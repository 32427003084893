import superagent from "superagent"
import { msgErr } from './notify'
import { sessionToken } from './Auth'
import {
	ErrorInvalidParameters,
	ErrorUnauthorized,
} from "./errors"
import config from "./config"
export { useAuth, setAuthToken } from './Auth'

// Local storage
export const setVar = (key, value) =>
	value===undefined ? localStorage.removeItem(key) : localStorage.setItem(key, JSON.stringify(value))
export const getVar = key => {
	const value = localStorage.getItem(key)
	return value ? JSON.parse(value) : undefined
}

// type RequestFn = (path: string, params?: Record<string, any>) => Promise<any>
export const request = async (path, params={}) => {
	const apiGateway = config?.apiGateway || `${window.location.origin}`
	const url = apiGateway + (path[0] === '/' ? '' : '/') + path
	// const sessToken = getVar(SESSION_TOKEN_VAR)

	const req = superagent
		.post(url)
		.type('json')
		.send(params)
	if(sessionToken)
		req.set('Authorization', 'Bearer ' + sessionToken)
	return req
		.then(response => response.body)
		.catch(error => {
			console.error('API request failed', error, error.status)
			if(error.status === 401) {
				msgErr(error.response.text)
				throw new ErrorUnauthorized(error.response.text, error)
			}
			else if(error.status === 422)
				throw new ErrorInvalidParameters('DTO validation failed', error.response.body)
			else {
				msgErr(error.response.text)
				throw new Error(error.response.text)
			}
		})
}

export const requestUpload = (path, file) => {
	const apiGateway = config?.apiGateway || `${window.location.origin}`
	const url = apiGateway + (path[0] === '/' ? '' : '/') + path
	// const sessToken = getVar(SESSION_TOKEN_VAR)

	const req = superagent
		.post(url)
		.set('X-Filename', file.name)
		.set('X-Content-Type', file.type)
		.send(file)
	if(sessionToken)
		req.set('Authorization', 'Bearer ' + sessionToken)

	return req
		// .on('progress', (event) => {
		// 	console.log(`Uploaded ${Math.round(event.percent)}%`);
		// })
		.catch(error =>
			console.error('Upload failed:', error)
		)
		.then(res => res.text)
}

export const requestDownload = async (path, params={}) => {
	const apiGateway = config?.apiGateway || `${window.location.origin}`
	const url = apiGateway + (path[0] === '/' ? '' : '/') + path
	// const sessToken = getVar(SESSION_TOKEN_VAR)

	const req = superagent
		.post(url)
		.send(params)
		.responseType('blob')
	if(sessionToken)
		req.set('Authorization', 'Bearer ' + sessionToken)
	const response = await req

	const contentDisposition = response.get('content-disposition')
	let filename = 'downloaded-file'
	if(contentDisposition && contentDisposition.includes('filename=')) {
		const filenameMatch = contentDisposition.match(/filename="?([^"]+)"?/)
		if(filenameMatch.length > 1)
			filename = filenameMatch[1]
	}

	const blob = new Blob([response.body], { type: response.body.type })
	const downloadUrl = window.URL.createObjectURL(blob)

	const link = document.createElement('a')
	link.href = downloadUrl
	link.download = filename
	document.body.appendChild(link)
	link.click()

	document.body.removeChild(link)
	window.URL.revokeObjectURL(downloadUrl)
}
